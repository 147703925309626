const Mastercard = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="29"
      viewBox="0 0 44 29"
      fill="none"
      {...props}
    >
      <path
        d="M40.5266 2.44775C41.5242 2.44775 42.3424 3.266 42.3424 4.26354V25.1056C42.3424 26.1032 41.5242 26.9214 40.5266 26.9214H3.47399C2.47645 26.9214 1.6582 26.1032 1.6582 25.1056V4.26354C1.6582 3.266 2.47645 2.44775 3.47399 2.44775H40.5266Z"
        stroke="#E9E9E9"
      />
      <path
        d="M17.3689 22.7891C21.8453 22.7891 25.4742 19.1603 25.4742 14.6839C25.4742 10.2075 21.8453 6.57861 17.3689 6.57861C12.8925 6.57861 9.26367 10.2075 9.26367 14.6839C9.26367 19.1603 12.8925 22.7891 17.3689 22.7891Z"
        fill="#EB001B"
      />
      <path
        d="M26.6307 22.7891C31.1071 22.7891 34.7359 19.1603 34.7359 14.6839C34.7359 10.2075 31.1071 6.57861 26.6307 6.57861C22.1542 6.57861 18.5254 10.2075 18.5254 14.6839C18.5254 19.1603 22.1542 22.7891 26.6307 22.7891Z"
        fill="#F79E1B"
      />
      <path
        d="M25.4728 14.6845C25.4728 11.9055 24.0833 9.47395 21.9991 8.08447C19.9149 9.58974 18.5254 12.0213 18.5254 14.6845C18.5254 17.3476 19.9149 19.895 21.9991 21.2845C24.0833 19.895 25.4728 17.4634 25.4728 14.6845Z"
        fill="#FF5F00"
      />
    </svg>
  )
}

export default Mastercard
